<template>
  <div>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="treatmentHistories"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex">
          <vs-select
            class="select"
            :placeholder="currentYear"
            autocomplete
            v-model="selectedYear"
            @change="valChanged()"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in yearDropdown"
            />
          </vs-select>

          <vs-select
            class="select"
            @change="valChanged()"
            :placeholder="monthDropdown[currentMonth - 1]"
            autocomplete
            v-model="selectedMonth"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in monthDropdown"
            />
          </vs-select>

          <vs-button style="padding: 10px" @click="submitQuery"
            >Filter</vs-button
          >
        </div>

        <template slot="thead">
          <vs-th sort-key="appointmentDateTime">Date</vs-th>
          <vs-th sort-key="patientDetail.name">Patient</vs-th>
          <vs-th sort-key="nurseDetail.name">Nurse</vs-th>
          <vs-th sort-key="doctorDetail.name">Doctor</vs-th>
          <vs-th sort-key="treatmentPlans.product">Product</vs-th>
          <vs-th sort-key="treatmentPlans.area">Area</vs-th>
          <vs-th sort-key="treatmentPlans.amount">Volume</vs-th>
          <vs-th sort-key="status">Status</vs-th>
          <vs-th>Details</vs-th>
          <vs-th v-if="check_has_permission('viewClinicTreatmentDetail')"
            >Action</vs-th
          >
        </template>

        <tbody>
          <tr
            style="border-bottom: 1px solid #ddd"
            :key="index"
            v-for="(trr, index) in treatmentHistories"
            :class="
              showHideArray.includes(trr.appointmentId) ? 'coloredTr' : ''
            "
          >
            <td style="vertical-align: baseline">
              {{ trr.appointmentDateTime | date_formatter }}
            </td>
            <td style="vertical-align: baseline">
              {{ trr.patientDetail.name || "N/A" }}
            </td>
            <td style="vertical-align: baseline">
              {{ trr.nurseDetail.name || "N/A" }}
            </td>
            <td style="vertical-align: baseline">
              {{ trr.doctorDetail.name || "N/A" }}
            </td>
            <td style="vertical-align: baseline">
              <table>
                <tr
                  :class="
                    showHideArray.includes(trr.appointmentId) ? 'coloredTr' : ''
                  "
                  :key="trrr._id"
                  v-for="(trrr, treatmentPlanIndex) in trr.treatmentPlans"
                >
                  <td
                    style="vertical-align: baseline"
                    v-if="showHideArray.includes(trr.appointmentId)"
                  >
                    {{ trrr.product || "N/A" }}
                  </td>
                  <td
                    style="vertical-align: baseline"
                    v-else-if="treatmentPlanIndex === 0"
                  >
                    {{ trrr.product || "N/A" }}
                  </td>
                </tr>
              </table>
            </td>
            <td style="vertical-align: baseline">
              <table>
                <tr
                  :class="
                    showHideArray.includes(trr.appointmentId) ? 'coloredTr' : ''
                  "
                  :key="trrrr._id"
                  v-for="(trrrr, treatmentPlanIndex) in trr.treatmentPlans"
                >
                  <td
                    style="vertical-align: baseline"
                    v-if="showHideArray.includes(trr.appointmentId)"
                  >
                    {{ trrrr.area || "N/A" }}
                  </td>
                  <td
                    style="vertical-align: baseline"
                    v-else-if="treatmentPlanIndex === 0"
                  >
                    {{ trrrr.area || "N/A" }}
                  </td>
                </tr>
              </table>
            </td>
            <td style="vertical-align: baseline">
              <table>
                <tr
                  :class="
                    showHideArray.includes(trr.appointmentId) ? 'coloredTr' : ''
                  "
                  :key="trrrrr._id"
                  v-for="(trrrrr, treatmentPlanIndex) in trr.treatmentPlans"
                >
                  <td
                    style="vertical-align: baseline"
                    v-if="showHideArray.includes(trr.appointmentId)"
                  >
                    {{ trrrrr.amount || "N/A" }}
                  </td>
                  <td
                    style="vertical-align: baseline"
                    v-else-if="treatmentPlanIndex === 0"
                  >
                    {{ trrrrr.amount || "N/A" }}
                  </td>
                </tr>
              </table>
            </td>
            <td style="vertical-align: baseline">{{ trr.status }}</td>
            <td
              style="vertical-align: baseline"
              @click="toogleData(trr.appointmentId, trr.treatmentPlans.length)"
            >
              <div v-if="trr.treatmentPlans.length > 1">
                <span
                  style="display: flex; align-items: center; cursor: pointer"
                  v-if="showHideArray.includes(trr.appointmentId)"
                >
                  <chevron-up-icon
                    size="1x"
                    class="custom-class"
                  ></chevron-up-icon
                  >Less Detail
                </span>
                <span
                  v-else
                  style="display: flex; align-items: center; cursor: pointer"
                >
                  <chevron-down-icon
                    size="1x"
                    class="custom-class"
                  ></chevron-down-icon
                  >More Detail
                </span>
              </div>
              <!-- <vs-button
                v-if="showHideArray.includes(trr.appointmentId)"
                style="padding: 10px;"
                icon-pack="feather"
                icon="icon-chevron-up"
              >Less Detail</vs-button>
              <vs-button
                v-else
                style="padding: 10px;"
                icon-pack="feather"
                icon="icon-chevron-down"
              >More Detail</vs-button>-->
            </td>

            <td
              style="vertical-align: baseline"
              v-if="check_has_permission('viewClinicTreatmentDetail')"
            >
              <ViewTreatmentHistoryDetailButton
                :checkInId="trr.patientCheckinId"
                :patientId="trr.patientDetail._id"
                :dateRange="dr === '' ? firstWeekData : dr"
                :month="selectedMonth"
                :year="selectedYear"
                :clinicId="clinicId"
                :activeView="viewSelected"
              />
            </td>
          </tr>
        </tbody>
      </vs-table>

      <div class="m-2">
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import { ChevronDownIcon, ChevronUpIcon } from "vue-feather-icons";
import ViewTreatmentHistoryDetailButton from "@/views/components/viewTreatmentHistoryDetailButton";

export default {
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    ViewTreatmentHistoryDetailButton,
  },
  props: {
    inTabEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      weekRangeClicked: false,
      dr: "",
      viewSelected: "month",
      noDataText: "Loading...",
      isMounted: false,
      totalDocs: 0,
      dataTableParams: {
        search: "",
        sort: "appointmentDateTime",
        dir: "desc",
        page: 1,
        limit: 25,
      },
      serverResponded: false,
      treatmentHistories: [],
      limitOptions: [5, 10, 25, 50, 100],
      yearDropdown: [],
      currentYear: new Date().getFullYear(),
      monthDropdown: [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      currentMonth: new Date().getMonth() + 1,
      weekArray: [],
      firstWeekData: "",
      selectedMonth: "",
      selectedYear: new Date().getFullYear(),
      treatmentPlanIndexProduct: 0,
      showHideArray: [],
      clinicId: null,
      awaitingSearch: null,
    };
  },
  methods: {
    ...mapActions("clinic", ["fetchAllTreatmentHistory"]),
    getTreatmentHistory() {
      this.$vs.loading();
      if (this.$route.params.clinicId)
        this.clinicId = this.$route.params.clinicId;
      else this.clinicId = this.$store.state.AppActiveUser._id;

      this.fetchAllTreatmentHistory({
        dateRange: this.dr,
        options: this.dataTableParams,
        clinicId: this.clinicId,
      })
        .then((res) => {
          if (res.data.data.permissions) {
            this.$store.commit(
              "UPDATE_USER_PERMISSION",
              res.data.data.permissions
            );
          }
          this.treatmentHistories = res.data.data.docs;
          this.totalDocs = res.data.data.pagination.total;
          this.page = res.data.data.pagination.page;
          this.serverResponded = true;
          this.noDataText = "No Treatment History Found";
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.noDataText = "No Treatment History Found";
          this.$vs.loading.close();
        });
    },
    getYearDropdown() {
      for (let i = 2020; i <= this.currentYear; i++) {
        this.yearDropdown.push(i);
      }
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getTreatmentHistory();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getTreatmentHistory();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getTreatmentHistory();
    },
    getAllWeeksForMonth(
      year,
      month,
      flag = false,
      param = null,
      extraFlag = null
    ) {
      this.weekArray = [];
      let chose = false;
      const defDate = year + "-" + month + "-01";

      const firstDayWeekNum = new Date(defDate).getDay();

      const newStartDate = this.addDaysToDate(defDate, -firstDayWeekNum);

      let newDate = new Date(newStartDate + "T00:00:00.000Z").getTime();

      const monthEndDate = this.getEndDateOfMonth(year + "-" + month);
      const newEndDate = new Date(monthEndDate + "T23:59:59.999Z").getTime();

      const endDayWeekNum = new Date(monthEndDate).getDay();

      let cnt = 1;

      while (newDate <= newEndDate) {
        if (cnt > 6) {
          break;
        }

        let weekEndDate = this.addDaysToDate(newDate, 6);
        let weekStartDate = this.addDaysToDate(weekEndDate, -6);

        this.weekArray.push(
          `${moment(weekStartDate).format("DD/MM/YYYY")} - ${moment(
            weekEndDate
          ).format("DD/MM/YYYY")}`
        );

        if (
          new Date().getTime() >= newDate &&
          new Date().getTime() <= newEndDate &&
          flag
        ) {
          chose = true;
          this.firstWeekData = `${moment(weekStartDate).format(
            "DD/MM/YYYY"
          )} - ${moment(weekEndDate).format("DD/MM/YYYY")}`;
        }
        newDate = this.addDaysToDate(weekEndDate, 1);
        newDate = new Date(newDate + "T00:00:00.000Z").getTime();
        cnt++;
      }

      if (!chose && !flag && param != "week") {
        this.firstWeekData = this.weekArray[0];
      }

      if (
        this.$route.query.dateRange &&
        !this.weekRangeClicked &&
        extraFlag !== "extraFlag"
      ) {
        this.firstWeekData = this.$route.query.dateRange;
      }
    },
    addDaysToDate(date, days) {
      const formatDate = new Date(date);
      return moment(formatDate, "YYYY-MM-DD")
        .add(days, "days")
        .format("YYYY-MM-DD");
    },
    getEndDateOfMonth(yearAndMonth) {
      return moment(yearAndMonth, "YYYY-M").endOf("month").format("YYYY-MM-DD");
    },
    valChanged() {
      const monthNumber = this.monthDropdown.indexOf(this.selectedMonth);
      const startDateOfMonth = `01/${monthNumber + 1}/${this.selectedYear}`;
      const endDateOfMonth = moment(startDateOfMonth, "DD/MM/YYYY")
        .endOf("month")
        .format("DD/MM/YYYY");

      this.dr = `${startDateOfMonth} - ${endDateOfMonth}`;
    },
    submitQuery() {
      this.serverResponded = false;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getTreatmentHistory();
    },
    toogleData(appointmentId, arrLength) {
      if (arrLength > 1) {
        if (this.showHideArray.includes(appointmentId)) {
          const index = this.showHideArray.indexOf(appointmentId);
          if (index > -1) {
            this.showHideArray.splice(index, 1);
          }
        } else {
          this.showHideArray.push(appointmentId);
        }
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getTreatmentHistory();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getTreatmentHistory();
      }
    },
  },
  created() {
    if (this.$route.query.year) {
      this.selectedYear = this.$route.query.year;
      this.currentYear = this.$route.query.year;
    }

    if (this.$route.query.month) {
      this.selectedMonth = this.$route.query.month;
      this.currentMonth =
        this.monthDropdown.indexOf(this.$route.query.month) + 1;
    } else {
      this.selectedMonth = this.monthDropdown[new Date().getMonth()];
    }

    this.getYearDropdown();
    this.valChanged();
    this.getTreatmentHistory();
  },
};
</script>

<style scoped>
.select {
  width: 65px;
  margin-right: 5px;
}
.selectClinic {
  width: 120px;
  margin-right: 5px;
}
.week-select {
  width: 195px;
  margin-right: 5px;
}
td {
  font-size: 15px !important;
  white-space: nowrap !important;
}

.closeTd {
  display: none;
}

.openTd {
  display: block;
}

.detailText {
  cursor: pointer;
  color: blue;
}

.coloredTr {
  background: aliceblue;
}

.weeklyViewButton {
  border: 1px solid;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  cursor: pointer;
  border-color: #aaa !important;
  color: #666 !important;
  box-shadow: 0 1px 3px #eee;
}

.monthlyViewButton {
  border: 1px solid;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
  border-color: #aaa !important;
  color: #666 !important;
  box-shadow: 0 1px 3px #eee;
}

.selectedMonthView {
  background: #aaa;
  color: #fff;
}

.selectedView {
  background: #aaa;
  color: #fff;
}

.selectedView .svgIcon {
  color: #fff;
}
</style>
